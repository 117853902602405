import React, { useRef, useEffect } from 'react';

const usePortal = (id, classes, size) => {
    const rootElemRef = useRef(null);

    useEffect(() => {

        // Look for existing target dom element to append to
        const existingParent = document.querySelector(`#${id}`);
        // Parent is either a new root or the existing dom element
        const parentElem = existingParent || createRootElement(id, classes);

        // If there is no existing DOM element, add a new one.
        if (!existingParent) {
            addRootElement(parentElem);
        }

        //remove scrolling
        document.body.style.overflow = 'hidden'

        // Add the detached element to the parent
        parentElem.appendChild(rootElemRef.current);

        return function removeElement() {
            ////adding scrolling on modal close
            document.body.style.overflow = 'auto'
            rootElemRef.current.remove();
            if (parentElem.childNodes?.length === 0) {

                parentElem.remove();
            }
        };
    }, []);

    const createRootElement = (id, classes) => {
        const rootContainer = document.createElement('div');
        rootContainer.setAttribute('id', id);
        rootContainer.setAttribute('class', classes);
        return rootContainer;
    };

    const addRootElement = rootElem => {
        document.body.insertBefore(
            rootElem,
            document.body.lastElementChild.nextElementSibling,
        );
    }

    const getRootElem = () => {

        if (!rootElemRef.current) {
            rootElemRef.current = document.createElement('div');
            rootElemRef.current.setAttribute('class', size);
        }
        return rootElemRef.current;
    }

    return getRootElem();
}

export default usePortal;
