import { useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react'
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts'
import { fetchServerStats } from 'services';

let chart = {
    options: {
        chart: {
            height: 150,
            type: 'radialBar',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                    margin: 0,
                    size: '70%',
                    background: '#fff',
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: 'front',
                    dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                    }
                },
                track: {
                    background: '#fff',
                    strokeWidth: '67%',
                    margin: 0, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: -3,
                        left: 0,
                        blur: 4,
                        opacity: 0.35
                    }
                },

                dataLabels: {
                    show: true,
                    name: {
                        offsetY: -10,
                        show: true,
                        color: '#888',
                        fontSize: '17px'
                    },
                    value: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(2) + '%';
                        },
                        color: '#111',
                        fontSize: '20px',
                        show: true,
                    }
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: ['#ABE5A1'],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
            }
        },
        stroke: {
            lineCap: 'round'
        },
    },

};

const ServerStats = () => {

    const queryClient = useQueryClient();
    const [ramUsage, setRamUsage] = useState(0);

    const { data: serverStats } = useQuery(['serverStats'], fetchServerStats, {
        refetchInterval: 10000,
    })

    useEffect(() => {
        if (serverStats) {
            setRamUsage(calculateRamUsage());
        }
    }, [serverStats])


    let ramOptions = Object.assign({}, chart.options);
    ramOptions.labels = ['RAM'];

    let cpuOptions = Object.assign({}, chart.options);
    cpuOptions.labels = ['CPU'];

    const calculateRamUsage = () => {
        let totalMemory = serverStats?.memory_free + serverStats?.memory_used;
        let memoryInPercent = (serverStats?.memory_used / totalMemory) * 100

        return memoryInPercent.toFixed(2)
    }

    return (
        <div className='grid grid-cols-12 gap-x-2 items-center'>
            <div className='col-span-12 md:col-span-6'>
                <ReactApexChart options={ramOptions}
                    series={[ramUsage]}
                    type="radialBar" height={220} />
            </div>
            <div className='col-span-12 md:col-span-6'>
                <ReactApexChart options={cpuOptions} series={[serverStats?.cpu_usage_total <= 100 ? serverStats?.cpu_usage_total : 0]} type="radialBar" height={220} />
            </div>
        </div>
    )
}

export default ServerStats