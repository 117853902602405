import OutsideAlerter from 'components/OutsideClick';
import React from 'react'
import { BiBell } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import Styles from './notification.module.scss';
import NumberPoolNotification from './numberPoolNotification';

const Notification = ({ showNotificationDropDown, setShowNotificationDropDown }) => {

    const { numberPoolJobs } = useSelector(state => state.notifications);

    return (
        <OutsideAlerter onClick={() => { setShowNotificationDropDown(false) }}>
            {showNotificationDropDown && <div className={Styles.notificationContainer}>
                <div className={`flex p-3 ${Styles.title}`}>
                    <h3 className='text-blue'>Notifications</h3>
                    <span className={Styles.notificationIcon}>
                        <BiBell />
                    </span>
                </div>

                {numberPoolJobs && numberPoolJobs?.length > 0 &&
                    numberPoolJobs.map((jobDetails) => (
                        <NumberPoolNotification {...jobDetails} />
                    ))}

                {numberPoolJobs && numberPoolJobs?.length == 0 && <div className={Styles.noNotificationContainer}>
                    <h4>No Notifications</h4>
                    <p>You have currently no notifications. We'll notify you when something new arrives.</p>
                </div>}

            </div>}
        </OutsideAlerter>
    )
}

export default Notification