
import axios from 'axios'
import toast from 'react-hot-toast';
import localStorage from 'redux-persist/es/storage';
import { resetToken } from 'redux/slices/authenticationSlice';
import store from 'redux/store';
import configs from './config'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const API_SUFFIX_PATH = process.env.REACT_APP_BASE
const NET_DATA_BASE_URL = process.env.REACT_APP_NET_DATA_BASE_URL

export const api = axios.create({
    baseURL: API_BASE_URL
})

export const apiNetData = axios.create({
    baseURL: NET_DATA_BASE_URL
})

api.interceptors.request.use(
    function (config) {
        const { authentication } = store.getState()
        const { token } = authentication;
        config.headers.Authorization = token && token.access_token
            ? `${token.token_type} ${token.access_token}`
            : configs.BASIC_AUTH;


        if (token.selected_partition && token.selected_partition !== 'default') {
            if (process.env.REACT_APP_ENV !== 'development') {
                const partitionBasePath = token.selected_partition?.partition_base_path === '/' ? '' : token.selected_partition?.partition_base_path;
                config.baseURL = window.location.origin + partitionBasePath + API_SUFFIX_PATH
            } else {
                config.baseURL = token.selected_partition?.partition_url + API_SUFFIX_PATH
            }
        }

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);


api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {

        if (
            error.response.data.statusCode === 2051 ||
            error.response.data.statusCode === 2052
        ) {

            toast.error('Your session has been expired', { position: 'top-center' })

            //Resetting token
            window.location.href = '/login'
            localStorage.removeItem('persist:root');
            store.dispatch(resetToken())


        } else {
            toast.error(error.response.data.statusPhrase || error.response.data.statusCode)
        }
        throw error;
    }
);