import React from "react";
import ReactDatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import { BiCalendarWeek } from "react-icons/bi";
import MaskedInput from "react-text-mask";

import Styles from "./datePicker.module.scss";

const DatePicker = ({
  control,
  name,
  placeHolder,
  labelText,
  required = false,
  error,
  defaultValue = null,
  showTimeInput = true,
  flex,
  minDate = false,
}) => {
  return (
    <div className={`relative ${Styles.datePicker} ${flex}`}>
      <label className="form-control-label">{labelText}</label>

      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        rules={{ required: required }}
        render={({ field }) => (
          <ReactDatePicker
            className={`form-control digits ${error && "validation-error"}`}
            onChange={(date) => {
              field.onChange(date);
            }}
            minDate={minDate && new Date()} // Set the minimum date to today
            selected={field.value}
            placeholderText={`${placeHolder} (eg. dd/mm/yyyy)`}
            timeInputLabel="Time:"
            dateFormat={`dd/MM/yyyy ${showTimeInput ? "h:mm aa" : ""}`}
            showTimeInput={showTimeInput}
            // customInput={
            //     <MaskedInput
            //         mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            //         keepCharPositions={true}
            //         guide={false}
            //     />
            // }
          />
        )}
      />
      {!flex && (
        <span className={`absolute ${Styles.calendarIcon}`}>
          <BiCalendarWeek />
        </span>
      )}
    </div>
  );
};

export default DatePicker;
