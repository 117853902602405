import { createSlice, current } from '@reduxjs/toolkit'

// number_pool_id: res?.phone_number_import_id,
// pool_name: res?.phone_number_import_name,
// job_in_que: true
const initialState = {
    activeCalls: [],
    numberPoolJobs: [],
    activeSMS: [],
    notificationCount: {
        activeCalls: 0,
        numberPoolJobs: 0
    }
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setCallNotification: (state, action) => {
            const { payload } = action;
            let parsedResponse = JSON.parse(payload);
            let transactionId = parsedResponse.transaction_id;

            // const [filteredRecord] = current(state).activeCalls.filter(call => { return call?.transaction_id === transactionId });

            const index = current(state).activeCalls.findIndex((e) => e.transaction_id === transactionId);

            if (index === -1) {
                state.activeCalls.unshift({ ...parsedResponse, is_loop: 'NA' });
            } else {

                let tempArr = [...current(state).activeCalls];
                tempArr[index] = {
                    ...tempArr[index],
                    call_state: parsedResponse?.call_state,
                    timestamp: parsedResponse?.timestamp,
                    is_loop: ('is_loop' in parsedResponse) ? parsedResponse?.is_loop : 'NA',
                    cause_code: parsedResponse?.cause_code || null
                }

                state.activeCalls = tempArr;
            }
        },
        setSMSNotification: (state, action) => {
            const { payload } = action;
            let parsedResponse = JSON.parse(payload);
            let transactionId = parsedResponse.transaction_id;

            // const [filteredRecord] = current(state).activeCalls.filter(call => { return call?.transaction_id === transactionId });

            const index = current(state).activeSMS.findIndex((e) => e.transaction_id === transactionId);

            if (index === -1) {
                state.activeSMS.unshift({ ...parsedResponse, is_loop: 'NA' });
            } else {

                let tempArr = [...current(state).activeSMS];
                tempArr[index] = {
                    ...tempArr[index],
                    sms_state: parsedResponse?.sms_state,
                    timestamp: parsedResponse?.timestamp,
                    is_loop: ('is_loop' in parsedResponse) ? parsedResponse?.is_loop : 'NA',
                    cause_code: parsedResponse?.cause_code || null
                }

                state.activeSMS = tempArr;
            }
        },
        setNumberPoolJobs: (state, action) => {
            const { payload } = action;


            const phoneNumberImportId = payload.phone_number_import_id;
            const index = current(state).numberPoolJobs.findIndex((e) => e.phone_number_import_id === phoneNumberImportId);

            if (index === -1) {
                state.numberPoolJobs.push(payload);
            } else {
                let tempArr = [...current(state).numberPoolJobs];
                tempArr[index] = { ...payload };
                state.numberPoolJobs = tempArr;
            }
        },
        setNotificationCount: (state, action) => {
            const { payload } = action;
            const { notificationType, count } = payload;

            if (notificationType === 'activeCalls') {
                state.notificationCount.activeCalls = count !== 0 ? current(state).notificationCount.activeCalls + count : 0;
            } else if (notificationType === 'numberPoolJobs') {
                state.notificationCount.numberPoolJobs = count !== 0 ? current(state).notificationCount.numberPoolJobs + count : 0;
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { setCallNotification, setNumberPoolJobs, setNotificationCount, setSMSNotification } = notificationSlice.actions

export default notificationSlice.reducer