export const PIE_CHART = {
    chart: {
        width: 380,
        minHeight: 400,
        type: 'pie',

    },
    dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
            return opt.w.config.series[opt.seriesIndex]
        },
    },
    legend: {
        position: 'bottom',
    },


    labels: [],
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 200
            },
            legend: {
                position: 'bottom'
            }
        }
    }]
}

export const AREA_CHART = {
    chart: {
        id: "basic-area",
        type: 'area',
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        }
    },
    fill: {
        type: "gradient",
        gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
        }
    },
    dataLabels: {
        enabled: false,
    },
    xaxis: {
        categories: [1,2,3,4,5,6,7,8]
    },
    yaxis: {
        title: {
            // text: distribution_chart_type === '1' ? 'ASR' : 'ACD',
        }
    },
}