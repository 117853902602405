import { api } from "core/api";
import QueryString from "qs";
import { buildListParams } from "utils/helper";

export const fetchNumberPools = async (filters) => {
    try {
        const params = buildListParams(filters);
        const response = await api.get('numberPools', {
            params
        })
        return response.data;
    } catch (err) {
        console.log(err);
    }
}
export const importNumberPool = async (payload) =>
    await api.post('numberPools/import', payload).then((response) => response.data)


export const removeNumberPool = async (poolId) =>
    await api.delete(`numberPools/${poolId}`);

export const exportNumberPool = async (poolId) =>
    await api.get(`numberPools`, { params: { number_pool_id: poolId, action: 'export' } }).then((res) => res.data);