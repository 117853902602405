const POOL_TYPES = [
    {
        pool_type_id: 1,
        pool_type_name: 'A Pool'
    },
    {
        pool_type_id: 2,
        pool_type_name: 'B Pool'
    }
]

export default POOL_TYPES