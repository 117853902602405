import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Styles from "./checkGroup.module.scss";
import { Controller, useController } from "react-hook-form";
import { Checkbox } from "@mui/material";

const CheckboxGroup = ({ label, name, control, required = false, value }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox {...field} checked={value} color="primary" size="small" />
          }
          label={label}
        />
      )}
      rules={{ required }}
    />
  );
};

export default CheckboxGroup;
