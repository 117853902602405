import React from 'react'
import Styles from './slider.module.scss';
import Slider from '@mui/material/Slider';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';


const marks = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 50,
        label: '50%',
    },
    {
        value: 100,
        label: '100%',
    },
];

function valuetext(value) {
    return `${value}`;
}

const CustomSlider = ({ label, defaultValue = 20, setValue, name, control, register, checkBox = null }) => {
    return (
        <div className={`${Styles.sliderWrapper} flex flex-col w-full`}>
            <div className='flex justify-between items-center'>
                <label className='mb-1'>{label}</label>
                {checkBox && <FormGroup className={Styles.checkboxContainer}>
                    <FormControlLabel control={
                        <Checkbox size='small' />
                    } label={checkBox?.label} />
                </FormGroup>}
            </div>
            <Slider
                aria-label="Custom marks"
                defaultValue={defaultValue}
                getAriaValueText={valuetext}
                step={5}
                onChange={(event) => { setValue(name, event.target.value) }}
                valueLabelDisplay="auto"
                marks={marks}
            /></div>
    )
}

export default CustomSlider