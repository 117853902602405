
import { MdDashboard, MdMobileFriendly } from 'react-icons/md'
import { SiPytest } from 'react-icons/si';
import { GrPlan, GrShieldSecurity } from 'react-icons/gr';
import { BiUser } from 'react-icons/bi';

const Menus = [
    {
        id: 1,
        title: 'Dashboard',
        type: 'link',
        icon: <MdDashboard />,
        path: '/dashboard',
        children: []
    },
    {
        id: 2,
        title: 'Provisioning',
        icon: <MdMobileFriendly />,
        children: [
            {
                title: 'Supplier Management',
                submenu: [
                    {
                        id: 'supplier-1',
                        title: 'Voice',
                        path: '/supplier-management/trunks'
                    },
                    {
                        id: 'supplier-2',
                        title: 'SMS',
                        path: '/supplier-management/sms'
                    }
                ]
            },
            {
                title: 'Number Pool Management',
                path: '/number-pool-management/pools',
            }
        ]
    },
    {
        id: 3,
        title: 'Test Case Management',
        icon: <SiPytest />,
        children: [
            {
                title: 'Test Cases',
                path: '/test-scenarios/scenarios'
            },
            {
                title: 'Add Test Case',
                path: '/test-scenarios/scenario'
            },
        ]
    },
    {
        id: 4,
        title: 'Hunter Plan',
        icon: <GrPlan />,
        children: [
            {
                title: 'Hunter Plans',
                path: '/hunter-plan/plans'
            },
            {
                title: 'Add Hunter Plan',
                path: '/hunter-plan/plan'
            },
        ]
    },
    {
        id: 5,
        title: 'Admin Panel',
        icon: <BiUser />,
        children: [
            {
                title: 'User Management',
                submenu: [
                    {
                        id: 'user-1',
                        title: 'Users',
                        path: '/user-management/users'
                    },
                    {
                        id: 'user-2',
                        title: 'Add User',
                        path: '/user-management/user'
                    },
                ]
            },
            // {
            //     title: 'Traffic Summary',
            //     path: '/',
            // }
        ]
    },
]
export default Menus;