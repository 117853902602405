import React, { useState } from "react";
import { Menus } from "@utils";
import { NavLink } from "react-router-dom";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import Styles from "./menuBar.module.scss";
import { useRef } from "react";
import { useOnHoverOutside } from "hooks/useOnHoverOutsite";
import { useQueryClient } from "@tanstack/react-query";

const MenuBar = () => {
  const subMenuRef = useRef(null); // Create a reference for sub dropdown container
  const queryClient = useQueryClient();
  const { user } = queryClient.getQueryData(["user"]) || {};
  const [subMenu, setSubMenu] = useState(false);
  const [subActiveMenuId, setSubActiveMenuId] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState();

  // Function to close dropdown
  const closeHoverMenu = () => {
    setActiveSubMenu(false);
  };

  useOnHoverOutside(subMenuRef, closeHoverMenu); // Call the hook

  return (
    <nav
      className={`${Styles.menuBar} w-full bg-white hidden md:block px-1 sm:px-4 py-2.5`}
    >
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <div className="w-full md:block md:w-auto" id="navbar-default">
          <ul className="flex flex-col p-1.5 mt-4 mx-4 md:flex-row md:space-x-4 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white ">
            {Menus.map(({ id, title, path, icon, children, type }) =>
              id !== 5 ? (
                <li key={id} className="relative">
                  {type === "link" ? (
                    <NavLink
                      to={path}
                      className={({ isActive }) =>
                        `block py-2 pr-4 pl-3 rounded  dark:text-white ${
                          Styles.menuItem
                        } ${isActive ? Styles.active : ""}`
                      }
                    >
                      {icon}
                      <span>{title}</span>
                    </NavLink>
                  ) : (
                    <a
                      className={`block py-2 pr-4 pl-3 rounded  dark:text-white hover:cursor-pointer`}
                      onClick={() => {
                        setSubMenu(!subMenu);
                        setSubActiveMenuId(id);
                      }}
                    >
                      {icon}
                      <span>{title}</span>
                      {children && children.length > 0 && <FaChevronDown />}
                    </a>
                  )}
                  {subMenu &&
                    id === subActiveMenuId &&
                    children &&
                    children.length > 0 && (
                      <ul className={Styles.subItem}>
                        {children.map(
                          ({ title, path, submenu = [] }, index) => (
                            <li key={index + 1}>
                              {submenu && submenu.length == 0 ? (
                                <NavLink
                                  onClick={() => {
                                    setSubMenu(false);
                                  }}
                                  to={path}
                                  className={({ isActive }) =>
                                    `block py-2 pr-4 pl-3 rounded  dark:text-white ${
                                      Styles.menuItem
                                    } ${isActive ? Styles.active : ""}`
                                  }
                                >
                                  <span>{title}</span>
                                </NavLink>
                              ) : (
                                <div ref={subMenuRef}>
                                  {" "}
                                  <a
                                    className={`block py-2 pr-4 pl-3 rounded hover:cursor-pointer dark:text-white ${Styles.menuItem}}`}
                                    onMouseOver={() => {
                                      setActiveSubMenu(true);
                                    }}
                                  >
                                    <span>{title}</span>
                                    {submenu && submenu.length > 0 && (
                                      <FaChevronRight />
                                    )}
                                  </a>
                                  {activeSubMenu &&
                                    submenu &&
                                    submenu.length > 0 && (
                                      <ul className={Styles.subMenu}>
                                        {submenu.map(({ title, path, id }) => (
                                          <li
                                            key={id}
                                            onClick={() => {
                                              setSubMenu(false);
                                              setActiveSubMenu(false);
                                            }}
                                          >
                                            <NavLink to={path}>{title}</NavLink>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                </div>
                              )}
                            </li>
                          )
                        )}
                      </ul>
                    )}
                </li>
              ) : (
                user?.is_super_admin &&
                user?.partitions?.length > 0 && (
                  <li key={id} className="relative">
                    {type === "link" ? (
                      <NavLink
                        to={path}
                        className={({ isActive }) =>
                          `block py-2 pr-4 pl-3 rounded  dark:text-white ${
                            Styles.menuItem
                          } ${isActive ? Styles.active : ""}`
                        }
                      >
                        {icon}
                        <span>{title}</span>
                      </NavLink>
                    ) : (
                      <a
                        className={`block py-2 pr-4 pl-3 rounded  dark:text-white hover:cursor-pointer`}
                        onClick={() => {
                          setSubMenu(!subMenu);
                          setSubActiveMenuId(id);
                        }}
                      >
                        {icon}
                        <span>{title}</span>
                        {children && children.length > 0 && <FaChevronDown />}
                      </a>
                    )}
                    {subMenu &&
                      id === subActiveMenuId &&
                      children &&
                      children.length > 0 && (
                        <ul className={Styles.subItem}>
                          {children.map(
                            ({ title, path, submenu = [] }, index) => (
                              <li key={index + 1}>
                                {submenu && submenu.length == 0 ? (
                                  <NavLink
                                    onClick={() => {
                                      setSubMenu(false);
                                    }}
                                    to={path}
                                    className={({ isActive }) =>
                                      `block py-2 pr-4 pl-3 rounded  dark:text-white ${
                                        Styles.menuItem
                                      } ${isActive ? Styles.active : ""}`
                                    }
                                  >
                                    <span>{title}</span>
                                  </NavLink>
                                ) : (
                                  <div ref={subMenuRef}>
                                    {" "}
                                    <a
                                      className={`block py-2 pr-4 pl-3 rounded hover:cursor-pointer dark:text-white ${Styles.menuItem}}`}
                                      onMouseOver={() => {
                                        setActiveSubMenu(true);
                                      }}
                                    >
                                      <span>{title}</span>
                                      {submenu && submenu.length > 0 && (
                                        <FaChevronRight />
                                      )}
                                    </a>
                                    {activeSubMenu &&
                                      submenu &&
                                      submenu.length > 0 && (
                                        <ul className={Styles.subMenu}>
                                          {submenu.map(
                                            ({ title, path, id }) => (
                                              <li
                                                key={id}
                                                onClick={() => {
                                                  setSubMenu(false);
                                                  setActiveSubMenu(false);
                                                }}
                                              >
                                                <NavLink to={path}>
                                                  {title}
                                                </NavLink>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      )}
                                  </div>
                                )}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                  </li>
                )
              )
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MenuBar;
