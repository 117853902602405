import { Button } from "@components";
import { Collapse } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Styles from "./search.module.scss";

const Search = ({ children, setSearchValue, onClick }) => {
  const [collapse, setCollapse] = useState(false);
  const [value, setValue] = useState("");

  const Search = () => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (!value) {
      setSearchValue("");
    }
  }, [value]);

  return (
    <>
      <div className={Styles.searchContainer}>
        <div className={Styles.inputGroup}>
          <input
            className={`form-control ${Styles.input}`}
            placeholder="Search ..."
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                Search();
              }
            }}
            value={value}
          />
          <Button className={Styles.searchButton} onClick={Search}>
            Search
          </Button>
          {children && (
            <Button
              className={`${Styles.advanceSearchBtn}`}
              type="PrimaryOutline"
              onClick={() => {
                setCollapse(!collapse);
              }}
            >
              {!collapse ? <FaChevronDown /> : <FaChevronUp />}
            </Button>
          )}
        </div>
        <Collapse in={collapse}>
          <div className={Styles.advanceSearch}>
            {children}

            <div className={Styles.action}>
              <Button onClick={onClick}>Submit</Button>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default Search;
