import React, { useState, useEffect } from 'react';
import { Button } from '@components';
import { BiUpload } from 'react-icons/bi';
import { FiX } from 'react-icons/fi';
import Styles from './fileUpload.module.scss';
import { Ping } from '@uiball/loaders'
import SampleFile from '@assets/downloads/sample-number-pool-import.xlsx';
import { FaFileExcel } from 'react-icons/fa';


const FileUpload = ({ extensions, loading, onFileDrop, selectedFile, onRemoveFile, className, required = false, isError = false }) => {

    const [dragOver, setDragOver] = useState(false);

    const onDragOver = (e) => {
        e.preventDefault();
    };

    const dragEnter = (e) => {
        e.preventDefault();
        if (e) {
            setDragOver(true);
        }
    };

    const dragLeave = (e) => {
        e.preventDefault();
        if (e) {
            setDragOver(false);
        }
    };

    useEffect(() => {
        if (!selectedFile) setDragOver(false)
    }, [selectedFile])


    const onInputClick = (event) => {
        event.target.value = "";
    }

    const downloadSampleFile = () => {
        const link = document.createElement('a');
        link.href = SampleFile;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            {!selectedFile ? <div className={`${Styles.fileUploadContainer} ${(required && isError) && Styles.error} ${className}`}
                style={{ backgroundColor: dragOver && '#d3d3d3' }}
                onDragOver={onDragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={onFileDrop}>
                {!loading ? <>
                    <div className={Styles.uploadIcon}>
                        <span><BiUpload /></span>
                    </div>
                    {!dragOver && <p>
                        <div className={Styles.browse}>
                            <input
                                id="inputGroupFile04"
                                type="file"
                                className="hidden"
                                onChange={onFileDrop}
                                onClick={(e) => onInputClick(e)}
                            />
                            <label htmlFor="inputGroupFile04">Browse</label>
                        </div> your file or drag and drop it here
                    </p>}
                    {dragOver && <p>Drop your file</p>}
                    <label className={Styles.requirementsLabel}>Only {extensions} file</label>
                    <Button as='a' onClick={downloadSampleFile} icon={<FaFileExcel />}> Download Sample File</Button>
                </> :
                    <div>
                        <Ping
                            size={45}
                            speed={2}
                            color="black"
                        />
                    </div>}
            </div> :
                <div className={Styles.selectedFileContainer}>
                    <p>{selectedFile?.file_name} ({(selectedFile?.file_size / 1024).toFixed(2)} kilo bytes)</p>
                    <span onClick={onRemoveFile}>
                        <FiX />
                    </span>
                </div>}
        </>
    )
}

export default FileUpload