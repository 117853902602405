import { configureStore, } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist'
import { encryptTransform } from 'redux-persist-transform-encrypt';

import callNotificationReducer from './slices/callNotificationSlice';
import authenticationReducer from './slices/authenticationSlice'
import socketReducer from './slices/socketSlice';
import notificationReducer from './slices/notification';

const reducers = combineReducers({
    authentication: authenticationReducer,
    notifications: notificationReducer,
    socketConnection: socketReducer
});

const persistConfig = {
    transforms: [
        encryptTransform({
            secretKey: 'my-super-secret-key',
            onError: function (error) {
                // Handle the error.
            },
        }),
    ],
    key: 'root',
    storage,
    whitelist: ['authentication']
};

const persistedReducer = persistReducer(persistConfig, reducers);


const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export default store;