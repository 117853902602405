import React, { Suspense, useState, useEffect, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Loader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from "@components";
import MenuBar from "Layout/MenuBar";
import TopBar from "Layout/TopBar";
import { resetToken } from "redux/slices/authenticationSlice";
import { IdleTimer } from "components/IdleTimer";
import { IDLE_TIME_OUT_TIME, TOKEN_EXPIRE_TIME } from "utils/constants";

import { Socket } from "socket";
import { setSocketConnection } from "redux/slices/socketSlice";

const PrivateRoute = ({}) => {
  const [isConnected, setIsConnected] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [counter, setCounter] = useState(60);
  const idleTimeRef = useRef();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { socket } = useSelector((state) => state.socketConnection);
  const { token } = useSelector((state) => state.authentication);

  const userData = queryClient.getQueryData(["user"]);
  let countIntervalId;

  const location = useLocation();

  useEffect(() => {
    if (!isConnected) {
      setIsConnected(true);
      establishSocketConnection(token);
    }
  }, [isConnected]);

  useEffect(() => {
    if (userData) {
      console.log("PRIVATE ROUTING");
      idleTimeRef && idleTimeRef?.current?.start();
    }
    return () => {
      countIntervalId && clearInterval(countIntervalId);
      if (socket) {
        socket.close();
        setIsConnected(false);
      }
    };
  }, []);

  const establishSocketConnection = async (userData) => {
    try {
      const { socket: _socket } = await new Socket().CreateSocket(userData);
      console.log("_socket", _socket);
      dispatch(setSocketConnection(_socket));
    } catch (err) {}
  };

  useEffect(() => {
    if (counter && counter == 0) {
      logout();
    }
  }, [counter]);

  const logout = () => {
    queryClient.removeQueries();
    dispatch(resetToken());
    navigate("/login");
  };

  const onActive = () => {
    // Do some active action
  };

  const onIdle = () => {
    // Do some idle action like log out your user
    logout();
  };

  const onAction = () => {
    // Do something when a user triggers a watched event
  };

  const onPrompt = () => {
    // Fire a Modal Prompt
    countIntervalId = setInterval(() => {
      setCounter((prev) => prev - 1);
    }, 1000);
    setSessionExpired(true);
  };

  // return userData && userData.access_token && userData.user !== null ? (
  return userData &&
    userData.access_token &&
    userData.user !== null &&
    token?.selected_partition ? (
    <>
      <IdleTimer
        ref={idleTimeRef}
        element={document}
        timeout={TOKEN_EXPIRE_TIME}
        promptTimeout={IDLE_TIME_OUT_TIME}
        onPrompt={onPrompt}
        onIdle={onIdle}
        onAction={onAction}
        onActive={onActive}
        startManually
      />

      <TopBar />
      <MenuBar />
      <Suspense fallback={<Loader />}>
        <div className="md:container mx-auto py-3 sm:px-4 h-fit">
          <Outlet />
        </div>
      </Suspense>

      {sessionExpired && (
        <Modal size="sm" id="sessionTimeoutModal" className="modal-wrapper">
          <ModalHeader>Session Timeout</ModalHeader>
          <ModalBody>
            Your session is timeout due to inactivity. You'll be logged off in{" "}
            {counter} seconds.
          </ModalBody>
          <ModalFooter>
            <Button
              className={"mr-2"}
              type="DangerOutline"
              size={"Small"}
              onClick={logout}
            >
              Logout
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
