import { NumberConverter } from "utils/conversion";

export class StatsFilter {
    granularity_days = null;

    constructor() {
        this.granularity_days = 'daily';
    }

    deserialize(model) {
        this.granularity_days = NumberConverter(model.granularity_days || 'daily');
        return this;
    }
}
