import React from "react";
import Styles from "./tiles.module.scss";

const Tiles = ({
  title,
  description,
  count = 0,
  isToday = false,
  type = 1,
}) => {
  return (
    <div
      className={`${Styles.tilesWrapper} ${type == 1 ? "bg-blue" : "bg-teal"}`}
    >
      <h1>
        {title} {isToday && <small className="text-white">(Now)</small>}
      </h1>
      <p>{description}</p>
      <h4 className="text-center mt-2">{count}</h4>
    </div>
  );
};

export default Tiles;
