import { api } from "core/api";

export const fetchLookUpData = async (lookUpName, params) => {
    const response = await api.get(`lookup/${lookUpName}`, { params });
    return response.data;
}

export const fetchSwitch = async () => {
    const response = await api.get('switch')
    return response.data;
}

export const fetchSMSTemplate = async (filters) => {
    const response = await api.get('smsContentTemplate', { params: filters });
    return response.data
}

export const fetchRemoteServers = async () => {
    const response = await api.get('remoteServers');
    return response.data;
}