
import React, { useEffect } from 'react';
import classes from './modal.module.scss';
import Flex from '../Flex';
import Button from '../Button';

const ModalFooter = ({
    className,
    onCancel,
    onSubmit,
    ok,
    cancel,
    children,
    ...rest
}) => {

    return (
        <Flex row justifyFlexEnd className={`${classes.modalFoot} ${className}`} {...rest}>
            {children}
        </Flex>
    )
}

export default ModalFooter;
