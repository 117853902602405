import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    token: {
        expires_in: '',
        token_type: '',
        access_token: '',
        refresh_token: '',
        selected_partition: '',
        partitions_list: []
    },
}

export const authenticationSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthToken: (state, action) => {
            state.token = { ...state.token, ...action.payload };
        },
        setSelectedPartition: (state, action) => {
            if (typeof action.payload === 'string') {
                state.token.selected_partition = action.payload;
            } else if (typeof action.payload === 'object') {
                // If payload is an object, spread its properties
                state.token.selected_partition = { ...state.token.selected_partition, ...action.payload };
            }
        },
        setPartitions: (state, action) => {
            state.token.partitions_list = action.payload
        },
        resetSelectedPartitions: (state, action) => {
            state.token.selected_partition = ""
        },
        resetToken: () => initialState
    },
})

// Action creators are generated for each case reducer function
export const { setAuthToken, resetToken, setPartitions, setSelectedPartition, resetSelectedPartitions } = authenticationSlice.actions

export default authenticationSlice.reducer