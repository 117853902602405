import moment from "moment";

export const buildParams = (filters) => {
    let queryParams = { ...filters };



    return queryParams;
}

export const formateWeekDay = (weekDay) => {
    switch (weekDay) {
        case 0:
            return 'Sat'
            break;
        case 1:
            return 'Sun'
            break;
        case 2:
            return 'Mon'
            break;
        case 3:
            return 'Tue'
            break;
        case 4:
            return 'Wed'
            break;
        case 5:
            return 'Thur'
            break;
        case 6:
            return 'Fri'
            break;
    }
}

export const calculateDate = (days = 30, currentDate = moment().endOf('day').format()) => {
    return new Date(moment(currentDate).add(days, 'days'))
}

export const generatePassword = (length) => {
    const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&()_<>.?/";
    let password = "";
    for (let i = 0; i < length; i++) {
        password += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return password;
}

export const buildListParams = (filter) => {
    let params = {};

    if (filter) {
        if (filter.searchText && filter.searchText != '') {
            if (filter.searchBy && filter.searchBy != '')
                params['searchBy'] = filter.searchBy
            params['searchText'] = filter.searchText;
        }

        if (filter.pagination) {
            params['pageNum'] = filter.pagination.pageNum;
            params['pageSize'] = filter.pagination.pageSize;
        }

        if (filter.region_ids && filter.region_ids.length > 0) {
            params.region_ids = filter.region_ids.join(",");
        }
        if (filter.trunk_directions && filter.trunk_directions.length > 0) {
            params.trunk_directions = filter.trunk_directions.join(",");
        }
        if (filter.pool_for) {
            params.pool_for = filter.pool_for;
        }
        if (filter.trunk_id) {
            params.trunk_id = filter.trunk_id;
        }
        if (filter.status) {
            params.status = filter.status;
        }
        if (filter.has_free_numbers) {
            params.has_free_numbers = filter.has_free_numbers;
        }
        if (filter.traffic_cycle_type) {
            params.traffic_cycle_type = filter.traffic_cycle_type;
        }
        if (filter.protocol_type) {
            params.protocol_type = filter.protocol_type;
        }
        if (filter.source_type) {
            params.source_type = filter.source_type;
        }
        if (filter.number_pool_type) {
            params.number_pool_type = filter.number_pool_type;
        }
        if (filter.is_running) {
            params.is_running = filter.is_running;
        }
    }
    return params;

}

export const copyToClipboard = async (text) => {
    try {

        const el = document.createElement("input");
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
    } catch (err) {
        console.log(err);
    }
}
