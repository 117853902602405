import React, { useState } from 'react'
import Styles from './notification.module.scss';

import { Ping } from '@uiball/loaders'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@components';

const NumberPoolNotification = ({ phone_number_import_id, pool_for, pool_name,
    statusCode = 0, statusPhrase, job_in_que = false, r_result = null }) => {

    const [modal, setModal] = useState(false)
    const toggleModal = () => setModal(!modal);

    return (
        <>
            <div className={Styles.notificationItem}>
                <div>
                    <h4>{pool_name}</h4>
                    {(phone_number_import_id && statusCode === 0) && <p>ID: <span>{phone_number_import_id}</span></p>}
                    {(phone_number_import_id && statusCode !== 0) && <p><span className='text-red'>Job Failed</span></p>}
                </div>
                <div>
                    {job_in_que ? <p className='flex items-center'>
                        <Ping
                            size={22}
                            speed={2}
                            color="#17A2B8"
                        />
                        <span className='ml-2 badge badge-info'>Job in progress</span>
                    </p> :
                        <p className='flex items-center'>
                            <span className={`ml-2 badge hover:cursor-pointer ${statusCode === 0 ? 'badge-primary' : 'badge-danger'}`} onClick={toggleModal}>
                                View details
                            </span>
                        </p>
                    }
                </div>
            </div>

            {modal && <Modal id={'numberPoolJobDetails'} className={'modal-wrapper'} size='sm'>
                <ModalHeader onClose={toggleModal}>
                    Number Pool Job Details
                </ModalHeader>
                <ModalBody>
                    <div className='w-full'>
                        <div className='flex justify-between mb-5'>
                            <p>Pool Name: <b>{pool_name}</b></p>
                            <p>Pool Type: <b>Pool {pool_for === 1 ? 'A' : 'B'}</b></p>
                        </div>
                        {statusCode === 0 ? <ul>
                            <li>Total Phone Numbers: <b>{r_result.phone_number_count}</b></li>
                            <li>Duplicate Numbers: <b>{r_result.duplicate_count}</b></li>
                            <li>Success count: <b>
                                {r_result.is_number_not_available_count < 0 ? r_result.phone_number_count : r_result.phone_number_count - r_result.is_number_not_available_count}
                            </b></li>
                            <li>Failed count: <b>
                                {r_result.is_number_not_available_count < 0 ? 0 : r_result.is_number_not_available_count}
                            </b></li>
                        </ul> : <p>{statusPhrase}</p>}
                    </div>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </Modal>}
        </>
    )
}

export default NumberPoolNotification