import { ADDRESS_NPI, ADDRESS_TON } from "assets/lookups";

export const TOKEN_EXPIRE_TIME = 5400000; //milliseconds
export const IDLE_TIME_OUT_TIME = 60000; //milliseconds
export const DEFAULT_PAGE_SIZE = 20;

export const TOAST_MESSAGES = {
    STOP_TEST_CASE: 'Successfully Stopped',
    RUN_TEST_CASE: 'Started Successfully',
    TRUNK_DELETE: `Trunk successfully deleted`,
    USER_DELETE: `User successfully deleted`,
    NUMBER_POOL_DELETE: `Number pool successfully deleted`
}

export const API_URLS = {

}

export const APP_ROUTES = {
    USER_CREATE: '/user-management/user',
    USER_LISTINGS: '/user-management/users'
}

export const TRUNK_PROPERTIES = [
    {
        key: 'addr_npi',
        label: 'Numeric Plan Indicator (NPI)',
        type: 'select',
        extra: {
            label: 'address_npi_value',
            value: 'address_npi_value',
            items: ADDRESS_NPI
        }
    },
    {
        key: 'addr_ton',
        label: 'Type of Number (TON)',
        type: 'select',
        extra: {
            label: 'address_ton_value',
            value: 'address_ton_value',
            items: ADDRESS_TON
        }
    },
    {
        key: 'addr_range',
        label: 'Address Range',
    },
    {
        key: 'channel_tx',
        label: 'Send',
        extraElement: <small>Channels</small>,
        validations: {
            maxLength: 4
        }
    },
    {
        key: 'channel_rx',
        label: 'Receive',
        extraElement: <small>Channels</small>,
        validations: {
            maxLength: 4
        }
    },
    {
        key: 'channel_trx',
        label: 'Send/Receive',
        extraElement: <small>Channels</small>,
        validations: {
            maxLength: 4
        }
    },
    {
        key: 'system_type',
        label: 'System Type',
    },
    {
        key: 'setup_time',
        label: 'Setup Time',
        validations: {
            maxLength: 3
        }
    },
    {
        key: 'reconnect_time',
        label: 'Reconnect Time',
    },
    {
        key: 'send_rate_limit',
        label: 'Send Rate Limit',
        validations: {
            maxLength: 3
        }
    },
    {
        key: 'enquire_link_time',
        label: 'Enquire Link Time',
        validations: {
            maxLength: 3
        }
    },
]
