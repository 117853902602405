import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import { Typography } from '@mui/material';
import { FaHome } from 'react-icons/fa';
import Styles from './breadcrumb.module.scss';

const Breadcrumbs = ({ home, parent, current }) => {
    const location = useLocation();
    return (
        <MUIBreadcrumbs aria-label="breadcrumb">
            <Link
                to="/dashboard"
                className='flex items-center'
            >
                <FaHome fontSize={'14'} />
            </Link>
            <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
            >

                {parent}
            </Link>
            <Typography
                sx={{ display: 'flex', alignItems: 'center' }}
                className={Styles.breadcrumbActive}
            >

                {current}
            </Typography>
        </MUIBreadcrumbs>
    )
}

export default Breadcrumbs