import { Suspense, lazy } from "react";
import { Routes, Route, ScrollRestoration } from "react-router-dom";

import { Loader, Modal, ModalBody, ModalFooter, ModalHeader } from "components";
import PrivateRoute from "Layout/PrivateRoute";

const Login = lazy(() => import("authentication/Login"));
const ChangePartition = lazy(() => import("authentication/ChangePartition"));
const Dashboard = lazy(() => import("views/Dashboard"));

const CreateScenario = lazy(() =>
  import("views/ScenarioManagement/CreateScenario")
);
const ScenarioList = lazy(() =>
  import("views/ScenarioManagement/ScenarioList")
);

const HunterPlanList = lazy(() =>
  import("views/HunterPlanManagement/PlanList")
);

const NumberPools = lazy(() => import("views/NumberPoolManagement/PoolLists"));
const NumberPool = lazy(() => import("views/NumberPoolManagement/CreatePool"));

const Trunks = lazy(() => import("views/TrunkManagement/TrunkList"));
const CreateTrunk = lazy(() => import("views/TrunkManagement/CreateTrunk"));

const SmsList = lazy(() => import("views/SmsSupplierManagement/SmsList"));
const CreateSMS = lazy(() =>
  import("views/SmsSupplierManagement/CreateSupplierSms")
);

const CreateHunterPlan = lazy(() =>
  import("views/HunterPlanManagement/CreatePlan")
);

const CreateUser = lazy(() => import("views/UserManagement/CreateUser"));
const Users = lazy(() => import("views/UserManagement/Users"));

const Summary = lazy(() => import("views/HunterPlanManagement/Summary"));

const AppRoutes = () => {
  const myDate = new Date();
  const hrs = myDate.getHours();

  let greet;

  if (hrs < 12) greet = "Good Morning";
  else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
  else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/change-partition" element={<ChangePartition />} />

          {/* Private Routes */}
          <Route element={<PrivateRoute allowedRoles={[1, 2]} />}>
            <Route path="/" element={<Dashboard title={`${greet}, `} />} />
            <Route
              path="/dashboard"
              element={<Dashboard title={`${greet}, `} />}
            />
            <Route
              path="/test-scenarios"
              children={[
                <Route
                  key={1}
                  path="/test-scenarios/scenarios"
                  element={
                    <ScenarioList
                      title={"Test Cases"}
                      parent="Test Case Management"
                    />
                  }
                />,
                <Route
                  key={2}
                  path="/test-scenarios/scenario"
                  element={
                    <CreateScenario
                      title={"Create Test Case"}
                      parent="Test Case Management"
                    />
                  }
                />,
                <Route
                  key={3}
                  path="/test-scenarios/scenario/:testCaseId"
                  element={
                    <CreateScenario
                      title={"Create Test Case"}
                      parent="Test Case Management"
                    />
                  }
                />,
              ]}
            />
            <Route
              path="/number-pool-management"
              children={[
                <Route
                  key={1}
                  path="/number-pool-management/pools"
                  element={
                    <NumberPools
                      title={"Number Pools"}
                      parent="Number Pool Management"
                    />
                  }
                />,
                <Route
                  key={2}
                  path="/number-pool-management/pool"
                  element={
                    <NumberPool
                      title={"Create Number Pool"}
                      parent="Number Pool Management"
                    />
                  }
                />,
                <Route
                  key={2}
                  path="/number-pool-management/pool/:numberPoolId"
                  element={
                    <NumberPool
                      title={"Edit Number Pool"}
                      parent="Number Pool Management"
                    />
                  }
                />,
              ]}
            />
            <Route
              path="/supplier-management"
              children={[
                <Route
                  key={1}
                  path="/supplier-management/trunks"
                  element={
                    <Trunks title={"Trunks"} parent="Supplier Management" />
                  }
                />,
                <Route
                  key={2}
                  path="/supplier-management/trunk"
                  element={
                    <CreateTrunk
                      title={"Create Trunk"}
                      parent="Supplier Management"
                    />
                  }
                />,
                <Route
                  key={3}
                  path="/supplier-management/trunk/:trunkId"
                  element={
                    <CreateTrunk
                      title={"Edit Trunk"}
                      parent="Supplier Management"
                    />
                  }
                />,
                <Route
                  key={4}
                  path="/supplier-management/sms"
                  element={
                    <SmsList title={"SMS"} parent="Supplier Management" />
                  }
                />,
                <Route
                  key={5}
                  path="/supplier-management/sms/create"
                  element={
                    <CreateSMS
                      title={"Create SMS Supplier"}
                      parent="Supplier Management"
                    />
                  }
                />,
                <Route
                  key={6}
                  path="/supplier-management/sms/:trunkId"
                  element={
                    <CreateSMS
                      title={"Edit SMS Supplier"}
                      parent="Supplier Management"
                    />
                  }
                />,
              ]}
            />

            <Route
              path="/hunter-plan"
              children={[
                <Route
                  key={1}
                  path="/hunter-plan/plans"
                  element={
                    <HunterPlanList
                      title={"Hunter Plans"}
                      parent="Hunter Plan Management"
                      testCaseType={4}
                    />
                  }
                />,
                <Route
                  key={2}
                  path="/hunter-plan/plan"
                  element={
                    <CreateHunterPlan
                      title={"Create Hunter Plan"}
                      parent="Hunter Plan Management"
                    />
                  }
                />,
                <Route
                  key={3}
                  path="/hunter-plan/plan/:testCaseId"
                  element={
                    <CreateHunterPlan
                      title={"Create Hunter Plan"}
                      parent="Hunter Plan Management"
                    />
                  }
                />,
                <Route
                  key={3}
                  path="/hunter-plan/plan/:testCaseId/summary"
                  element={
                    <Summary
                      title={"Summary"}
                      parent="Hunter Plan Management"
                    />
                  }
                />,
              ]}
            />

            <Route
              path="/user-management"
              children={[
                <Route
                  key={1}
                  path="/user-management/users"
                  element={
                    <Users
                      title={"Users"}
                      parent="User Management"
                      testCaseType={4}
                    />
                  }
                />,
                <Route
                  key={2}
                  path="/user-management/user"
                  element={
                    <CreateUser
                      title={"Create User"}
                      parent="User Management"
                    />
                  }
                />,
                <Route
                  key={3}
                  path="/user-management/user/:userId"
                  element={
                    <CreateUser
                      title={"Create User"}
                      parent="User Management"
                    />
                  }
                />,
              ]}
            />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
