import React, { useEffect } from "react";
import {
  Layout,
  Header,
  Tiles,
  PeriodFilter,
  ServerStats,
  TopLoader,
} from "@components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCDRReport, fetchSmsStats, getTilesData } from "services";
import Styles from "./dashboard.module.scss";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";
import { StatsFilter } from "model";
import { AREA_CHART, PIE_CHART } from "@utils";
import { Helmet } from "react-helmet";

const Dashboard = ({ title }) => {
  const queryClient = useQueryClient();
  const [filters, setFilters] = useState(new StatsFilter());
  const [smsFilters, setSMSFilters] = useState(new StatsFilter());
  const [filterLoading, setFilterLoading] = useState(false);
  const [smsFilterLoading, setSmsFilterLoading] = useState(false);

  const [durationPerCycle, setDurationPerCycle] = useState({
    series: [],
    options: PIE_CHART,
  });

  const [durationPerCycleArea, setDurationPerCycleArea] = useState({
    series: [11, 222, 444, 333, 1112, 444],
    options: AREA_CHART,
  });

  const [callCountPerCycle, setCallCountPerCycle] = useState({
    series: [],
    options: PIE_CHART,
  });

  const { user } = queryClient.getQueryData(["user"]) || {};

  const { data, isLoading, refetch } = useQuery(
    ["tiles"],
    () => {
      return getTilesData(filters);
    },
    {
      refetchInterval: 15000,
      refetchOnWindowFocus: true,
    }
  );

  const {
    data: smsStats,
    isLoading: smsDataLoading,
    refetch: smsRefetch,
  } = useQuery(
    ["sms_stats"],
    () => {
      return fetchSmsStats(smsFilters);
    },
    {
      refetchInterval: 15000,
      refetchOnWindowFocus: true,
    }
  );

  useEffect(() => {
    console.log(process.env.NODE_ENV);
    getDurationPerCycle();
    getCallsCountPerCycle();
    getDurationPerCycleArea();
  }, []);

  useEffect(() => {
    if (filters) {
      reFetchStats();
    }
  }, [filters]);

  useEffect(() => {
    if (smsFilters) {
      reFetchSMSStats();
    }
  }, [smsFilters]);

  const reFetchStats = async () => {
    try {
      setFilterLoading(true);
      await refetch();
      setFilterLoading(false);
    } catch (err) {
      setFilterLoading(false);
    }
  };

  const reFetchSMSStats = async () => {
    try {
      setSmsFilterLoading(true);
      await smsRefetch();
      setSmsFilterLoading(false);
    } catch (err) {
      setSmsFilterLoading(false);
    }
  };
  const onFilterChange = (filter) => {
    setFilters({ ...filters, granularity_days: filter });
  };
  const onSmsFilterChange = (filter) => {
    setSMSFilters({ ...smsFilters, granularity_days: filter });
  };

  const getDurationPerCycle = async () => {
    try {
      let cyclesArr = [];
      let durationArr = [];
      const response = await fetchCDRReport(null);
      response.listings.forEach(
        ({ traffic_cycle_name, total_billed_duration }) => {
          cyclesArr.push(traffic_cycle_name);
          durationArr.push(parseFloat(total_billed_duration));
        }
      );

      setDurationPerCycle({
        series: durationArr,
        options: { ...durationPerCycle.options, labels: cyclesArr },
      });
    } catch (err) {}
  };

  const getDurationPerCycleArea = async () => {
    try {
      let cyclesArr = [];
      let durationArr = [];
      const response = await fetchCDRReport(null);
      response.listings.forEach(
        ({ traffic_cycle_name, total_billed_duration }) => {
          cyclesArr.push(traffic_cycle_name);
          durationArr.push(parseFloat(total_billed_duration));
        }
      );

      setDurationPerCycleArea({
        series: durationArr,
        options: { ...durationPerCycle.options },
      });
    } catch (err) {}
  };

  const getCallsCountPerCycle = async () => {
    try {
      let cyclesArr = [];
      let callsCountArr = [];
      const response = await fetchCDRReport(null);
      response.listings.forEach(({ traffic_cycle_name, total_call_count }) => {
        cyclesArr.push(traffic_cycle_name);
        callsCountArr.push(parseFloat(total_call_count));
      });

      setCallCountPerCycle({
        series: callsCountArr,
        options: { ...durationPerCycle.options, labels: cyclesArr },
      });
    } catch (err) {}
  };

  return (
    <>
      <Helmet>
        <title>Testing Scenario</title>
      </Helmet>
      <Header
        title={`${title} ${user?.first_name} ${user?.last_name}`}
        __hide={true}
      ></Header>
      <div className="grid grid-cols-12 gap-4">
        <div className="md:col-span-8 col-span-12">
          <Layout className={"min-h-fit"}>
            <TopLoader showLoading={isLoading || filterLoading}>
              <div className="flex justify-between sm:flex-col mb-4">
                <h3 className="sm:mb-3">Voice Stats</h3>
                <PeriodFilter
                  filter={filters?.granularity_days}
                  onFilterChange={onFilterChange}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <Tiles
                  title={"Testing Scenarios"}
                  description={"Total no. of testing scenarios"}
                  count={data?.db_stats?.traffic_cycle_count}
                />
                <Tiles
                  title={"Success Calls"}
                  description={"Total no. of success calls"}
                  count={data?.db_stats?.total_loop_detected_count}
                />
                <Tiles
                  title={"Active Calls"}
                  isToday={true}
                  description={"Total no. of active calls"}
                  count={data?.switch_stats?.sessions?.active}
                />
                <Tiles
                  title={"Total Calls"}
                  description={"Total no. of Calls"}
                  count={data?.db_stats?.call_count}
                />
                <Tiles
                  title={"Total Duration"}
                  description={"Minutes"}
                  count={data?.db_stats?.total_billed_duration}
                />
                <Tiles
                  title={"Failed Calls"}
                  description={"Total no. of failed calls"}
                  count={data?.db_stats?.failed_calls}
                />
                <Tiles
                  title={"ACD"}
                  description={"Average Call Duration"}
                  count={data?.db_stats?.acd}
                />
                <Tiles
                  title={"ASR"}
                  description={"Answer-seizure ratio"}
                  count={`${data?.db_stats?.asr || 0}%`}
                />
              </div>
            </TopLoader>
          </Layout>
        </div>
        <div className="md:col-span-4 col-span-12">
          <Layout className={Styles.serverStats}>
            <h3 className="mb-5">Server Stats</h3>
            <ServerStats />
          </Layout>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="md:col-span-12 col-span-12">
          <Layout className={"min-h-fit"}>
            <TopLoader showLoading={smsDataLoading || smsFilterLoading}>
              <div className="flex justify-between sm:flex-col mb-4">
                <h3 className="sm:mb-3">SMS Stats</h3>
                <PeriodFilter
                  filter={smsFilters?.granularity_days}
                  onFilterChange={onSmsFilterChange}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <Tiles
                  title={"Testing Scenarios"}
                  description={"Total no. of testing scenarios"}
                  count={smsStats?.db_stats?.traffic_cycle_count}
                  type={2}
                />
                <Tiles
                  title={"SMS"}
                  description={"Total no. of sms"}
                  count={smsStats?.db_stats?.sms_count}
                  type={2}
                />
                <Tiles
                  title={"Success DLR"}
                  isToday={true}
                  description={"Total no. of success delivery"}
                  count={smsStats?.db_stats?.success_dlr_count}
                  type={2}
                />
                <Tiles
                  title={"Success Ratio"}
                  description={"%"}
                  count={smsStats?.db_stats?.success_ratio}
                  type={2}
                />
              </div>
            </TopLoader>
          </Layout>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="md:col-span-6 col-span-12">
          <Layout className={"min-h-fit"}>
            <TopLoader>
              <div className="mb-4">
                <h3 className="sm:mb-3">
                  Duration <small>(per cycle)</small>
                </h3>
                {/* <small>TODO: DATE - END DATE</small> */}
              </div>
              <div className="mt-8 flex justify-center items-center">
                <ReactApexChart
                  options={durationPerCycle.options}
                  series={durationPerCycle.series}
                  type="pie"
                  width={550}
                />
              </div>
            </TopLoader>
          </Layout>
        </div>
        <div className="md:col-span-6 col-span-12">
          <Layout className={"min-h-fit"}>
            <TopLoader>
              <div className="mb-4">
                <h3 className="sm:mb-3">
                  Calls Count <small>(per cycle)</small>
                </h3>
                {/* <small>TODO: START DATE - END DATE (FILTER)</small> */}
                {/* <PeriodFilter /> */}
              </div>
              <div className="mt-8 flex justify-center items-center">
                <ReactApexChart
                  options={callCountPerCycle.options}
                  series={callCountPerCycle.series}
                  type="pie"
                  width={550}
                />
              </div>
            </TopLoader>
          </Layout>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
