
import React, { useEffect } from 'react';

import classes from './modal.module.scss';
import Flex from '../Flex';
import Button from '../Button';
import Content from '../Typography';
import { FiX } from 'react-icons//fi';

const ModalHeader = ({ onClose, className, children, ...rest }) => {

    return (
        <Flex row justifyCenter className={`${classes.modalHead} ${className} pos-relative`} {...rest}>
            <Content as="label" className={classes.title}>{children}</Content>
            <Button
                as="link"
                className={`${classes.close} absolute`}
                onClick={onClose}
            >
                <FiX />
            </Button>
        </Flex>
    )
}

export default ModalHeader;
