import React, { useState } from "react";

import { Drawer } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import { BiPhone } from "react-icons/bi";
import { FiX } from "react-icons/fi";
import Countdown from "react-countdown";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Styles from "./callNotification.module.scss";
import { Table, TableBody, TableHead } from "@components";
import { MdRssFeed, MdSms } from "react-icons/md";

/*
 * TimeStamp, Calling, Called, Trunk Name, Call Status
 */
const CallNotifications = ({ notifications, setNotifications }) => {
  const { activeCalls, activeSMS } = useSelector(
    (state) => state.notifications
  );
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setNotifications(open);
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
    } else {
      // Render a countdown
      return (
        <span>
          {zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}
        </span>
      );
    }
  };

  const zeroPad = (num, places) => String(num).padStart(places, "0");

  return (
    <Drawer anchor={"right"} open={notifications} onClose={toggleDrawer(false)}>
      <div className={Styles.callsContainer}>
        <div className={Styles.title}>
          <h3>
            <MdRssFeed /> Live Stats
          </h3>
          <span
            className={Styles.closeBtn}
            onClick={() => {
              setNotifications(false);
            }}
          >
            <FiX />
          </span>
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon position tabs example"
        >
          <Tab icon={<BiPhone />} iconPosition="start" label="Call" />
          <Tab icon={<MdSms />} iconPosition="start" label="SMS" />
        </Tabs>
        {value == 0 && (
          <Table>
            <TableHead>
              <th>Date</th>
              <th>Calling Number</th>
              {<th>Called Number</th>}
              <th>Trunk IP</th>
              <th className="text-center">Call Status</th>
              <th className="text-center" colSpan={2}>
                Status
              </th>
            </TableHead>
            <TableBody>
              {activeCalls &&
                activeCalls.length > 0 &&
                activeCalls.map(
                  ({
                    transaction_id,
                    calling_number,
                    called_number,
                    timestamp,
                    trunk_id,
                    trunk_ip,
                    trunk_port,
                    setup_time,
                    call_state,
                    is_loop,
                    cause_code,
                    max_duration,
                  }) => (
                    <tr key={transaction_id}>
                      <td>
                        {moment(timestamp * 1000 || setup_time * 1000).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </td>
                      <td>{calling_number}</td>
                      <td>{called_number}</td>
                      <td>
                        {trunk_ip}:{trunk_port}
                      </td>
                      <td className="text-center relative">
                        <span
                          className={`badge 
                                        ${
                                          call_state == "ringing"
                                            ? "badge-dark"
                                            : call_state == "answered"
                                            ? "badge-success"
                                            : call_state == "hangup"
                                            ? "badge-info"
                                            : call_state == "scheduled"
                                            ? "badge-light"
                                            : "badge-primary"
                                        }`}
                        >
                          {call_state}
                        </span>
                        {call_state === "answered" && (
                          <span className={Styles.causeCode}>
                            <Countdown
                              zeroPadTime={2}
                              date={Date.now() + max_duration * 1000}
                              renderer={renderer}
                            />
                          </span>
                        )}
                      </td>
                      <td
                        className="text-center relative"
                        colSpan={2}
                        style={{ width: "66px" }}
                      >
                        {is_loop !== "NA" ? (
                          <span
                            className={`badge ${
                              is_loop ? "badge-success" : "badge-danger"
                            }`}
                          >
                            {is_loop ? "success" : "fail"}
                          </span>
                        ) : (
                          "-"
                        )}
                        {is_loop !== "NA" && !is_loop && cause_code && (
                          <small className={Styles.causeCode}>
                            {cause_code.toLowerCase()}
                          </small>
                        )}
                      </td>
                    </tr>
                  )
                )}
            </TableBody>
          </Table>
        )}
        {value == 1 && (
          <Table>
            <TableHead>
              <th>Date</th>
              <th>Sender ID</th>
              <th className="text-center">SMS State</th>
              <th className="text-center" colSpan={2}>
                Status
              </th>
            </TableHead>
            <TableBody>
              {activeSMS &&
                activeSMS.length > 0 &&
                activeSMS.map(
                  ({
                    transaction_id,
                    calling_number,
                    timestamp,
                    setup_time,
                    sms_state,
                    is_loop,
                    cause_code,
                    max_duration,
                  }) => (
                    <tr key={transaction_id}>
                      <td>
                        {moment(timestamp * 1000 || setup_time * 1000).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </td>
                      <td>{calling_number}</td>
                      <td className="text-center relative">
                        <span
                          className={`badge 
                                        ${
                                          sms_state == "scheduled"
                                            ? "badge-dark"
                                            : sms_state == "sent"
                                            ? "badge-success"
                                            : sms_state == "received"
                                            ? "badge-info"
                                            : sms_state == "scheduled"
                                            ? "badge-light"
                                            : "badge-primary"
                                        }`}
                        >
                          {sms_state}
                        </span>
                        {sms_state === "answered" && (
                          <span className={Styles.causeCode}>
                            <Countdown
                              zeroPadTime={2}
                              date={Date.now() + max_duration * 1000}
                              renderer={renderer}
                            />
                          </span>
                        )}
                      </td>
                      <td
                        className="text-center relative"
                        colSpan={2}
                        style={{ width: "66px" }}
                      >
                        {is_loop !== "NA" ? (
                          <span
                            className={`badge ${
                              is_loop ? "badge-success" : "badge-danger"
                            }`}
                          >
                            {is_loop ? "success" : "fail"}
                          </span>
                        ) : (
                          "-"
                        )}
                        {is_loop !== "NA" && !is_loop && cause_code && (
                          <small className={Styles.causeCode}>
                            {cause_code.toLowerCase()}
                          </small>
                        )}
                      </td>
                    </tr>
                  )
                )}
            </TableBody>
          </Table>
        )}
      </div>
    </Drawer>
  );
};

export default CallNotifications;
