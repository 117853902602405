import React from 'react'
import Styles from './layout.module.scss';

const Layout = ({ children, className }) => {
    return (
        <div className={`${Styles.layoutContainer} ${className}`}>
            {children}
        </div>
    )
}

export default Layout