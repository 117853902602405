import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRoutes from 'routes';
import { BrowserRouter, Route, Routes, ScrollRestoration } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import store from 'redux/store';
import { ErrorBoundary } from 'views';

import "react-datepicker/dist/react-datepicker.css";
import './index.scss';
import { Toaster } from 'react-hot-toast';

let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root'));

/**
 * StaleTime : The duration until a query transitions from fresh to stale. As long as the query is fresh, data will always be read from the cache only
 */
const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: Infinity, refetchOnWindowFocus: false, cacheTime: 10, retry: false } } })


//Persist the queries, and stores them in local storage.
persistQueryClient({
  queryClient: queryClient,
  persister: createSyncStoragePersister({
    storage: window.localStorage,
  }),
  maxAge: Infinity,
})

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <Routes>
                <Route path='/*' element={<AppRoutes />} />
              </Routes>
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen styleNonce='' />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
    <Toaster position='bottom-right' />
  </React.StrictMode>
);
