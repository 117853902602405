import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Styles from "./radioGroup.module.scss";
import { Controller } from "react-hook-form";

const RadioButton = ({
  label,
  name,
  control,
  required = false,
  disabled = false,
  radioItems = [],
}) => {
  return (
    <FormControl component={"fieldset"}>
      <FormLabel component={"legend"} className={`label ${Styles.label}`}>
        {label}
      </FormLabel>
      <Controller
        rules={{ required: required }}
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup {...field} row disabled={disabled}>
            {radioItems.map(({ key, label, value, is_disabled }) => (
              <FormControlLabel
                className={Styles.label}
                value={value}
                key={key}
                control={
                  <Radio size="small" disabled={is_disabled || disabled} />
                }
                label={label}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};

export default RadioButton;
