import React, { useState } from 'react'
import ServerError from '@assets/img/server-error.svg';
import NoData from '@assets/img/no-data.svg';
import { Button, Flex, Modal, ModalBody, ModalFooter, ModalHeader, Typography } from '@components';

const Error = ({ errorType, errorTitle, error, errorResponse }) => {

    const [errorDetailsModal, setErrorDetailsModal] = useState(false);


    return (
        <>
            <Flex row justifyCenter itemsCenter>
                <img src={errorType === 'serverError' ? ServerError : NoData} width="350" />
                <Flex column>
                    <div style={{ width: '300px' }}>
                        <Typography as='h4' className={'text-red'}>{errorTitle}</Typography>
                        <Typography as='span' className={'text-red'}>{errorResponse?.statusPhrase}</Typography>
                        {errorType === 'serverError' && <Button type={'DangerOutline'} size="Small" className={'mt-5'} onClick={() => { setErrorDetailsModal(true); }}>Details</Button>}                    </div>
                </Flex>
            </Flex>
            {errorDetailsModal && <Modal size="sm" id="errorDetailsModal" className="modal-wrapper">
                <ModalHeader onClose={() => { setErrorDetailsModal(false) }}>Error Details</ModalHeader>
                <ModalBody>
                    <div>
                        <ul>
                            <li><span className='font-bold'>Title:</span> {error?.message}</li>
                            <li className='flex '><span className='font-bold mr-2'>URL:  </span> {error?.request?.responseURL}</li>
                            <li><span className='font-bold'>Request Method: </span> {error?.response?.config?.method.toUpperCase()}</li>
                            <li><span className='font-bold'>Status Code: </span>
                                <span className='text-red'>{`${error?.response?.status} ${error?.response?.statusText}`}</span>
                            </li>
                        </ul>
                    </div>
                </ModalBody>
            </Modal>}
        </>
    )
}

export default Error