import { api } from "core/api";

export const getTilesData = async (filters) =>
    await api.get(`callingserver/cycles/metrics/status`, { params: filters }).then((response) => { return response.data })


export const fetchSmsStats = async (filters) =>
    await api.get(`smsserver/cycles/metrics/status`, { params: filters }).then((response) => { return response.data })

export const fetchCDRReport = async (filters) =>
    await api.get(`cdr/cdrCountsByTopTrafficCycles?date_period=8`, { params: filters }).then((response) => { return response.data })


export const fetchDynamicReportData = async (filters) => {

    let params = buildDatePeriodParams(filters);
    const response = await api.get(`cdr/cdrDynamicReport`, { params: params });
    return response.data
}
export const fetchDynamicReportDataTrunk = async (filters) => {
    let params = buildDatePeriodParams(filters);
    const response = await api.get(`cdr/cdrDynamicReport?grp_by_dst_trunk_id=true`, { params: params });
    return response.data;
}

export const fetchCDRRawData = async (filters) => {
    const _filters = Object.assign({}, filters);
    if (filters.is_loop_detected) {
        _filters.is_loop_detected = filters?.is_loop_detected == 1 ? true : false;

        if (filters?.is_loop_detected == 3) {
            delete _filters?.is_loop_detected;
        }
    }
    const response = await api.get('testcaseResult/call', { params: _filters })
    return response.data;
}

const buildDatePeriodParams = (filters) => {
    let params = Object.assign({}, filters)
    if (filters?.date_period == 'daily') {
        params['date_period'] = 1
    } else if (filters?.date_period == 'weekly') {
        params['date_period'] = 7
    } else if (filters?.date_period == 'monthly') {
        params['date_period'] = 8
    }
    return params;
}