import toast from 'react-hot-toast';

export { default as Menus } from './menus';
export { default as Routes } from './routes';
export * from './helper';
export * from './constants';
export * from './chartUtils';

export const validateFile = (fileExtension) => {
    switch (fileExtension) {
        case ".csv":
            return true;
        case ".xlsx":
            return true;
        default:
            toast.error(
                "Invalid File (only .xlsx, .csv allowed)"
                // "File with extension (.zip) is allowed"
            );
    }
};