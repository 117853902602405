
import React, { useEffect } from 'react';
import classes from './modal.module.scss';
import Flex from '../Flex';

const ModalBody = ({ className, children, ...rest }) => {

    return (
        <Flex row justifyBetween className={`${classes.modalBody} ${className}`} {...rest}>
            {children}
        </Flex>
    )
}

export default ModalBody;
