import io from 'socket.io-client';
import store from 'redux/store';
export const SOCKET_CONFIG = (userData = null) => {
    return {
        path: '/ttsWebSocket',
        transports: ["websocket"],
        autoConnect: true,
        reconnection: true,
        reconnectionAttempts: 10,
        reconnectionDelay: 2000,
        reconnectionDelayMax: 7000,
        query: {
            token: userData?.access_token
        }

    }
}

export const createSocketRequest = (channel) => {
    return { action: "subscribe", payload: { channel } }
}

export class Socket {

    socket;

    constructor() {

    }

    async CreateSocket(userData) {
        let socketURL = process.env.REACT_APP_SOCKET_URL;
        const { authentication } = store.getState()
        const { token } = authentication;

        if (token.selected_partition && token.selected_partition !== 'default') {
            socketURL = token.selected_partition?.partition_url.replace('/^https?:/', 'ws');
        }

        this.socket = await io(socketURL, SOCKET_CONFIG(userData));
        this.socket.connect();

        console.log("connect status", this.socket.connected);

        this.socket.on('connect', () => {
            console.log('<===================================== SOCKET CONNECTED =====================================>')
        });

        this.socket.on('reconnect_attempt', () => {
            console.log('<=====================================SOCKET RE-CONNECTING =====================================>')
        });

        this.socket.on('connect_error', (err) => {
            console.log('err', err);
            console.log('<===================================== ERROR CONNECTED =====================================>')
        });

        this.socket.on("disconnect", reason => { console.log("<===================================== SOCKET DISCONNECTED =====================================>", reason); }); //this.socket.close(); });
        this.socket.on("close", err => console.log("<===================================== SOCKET CLOSED =====================================>", err));
        return this;
    }


    CloseSocket() {
        this.socket.close();
    }
}

