import React, { useState } from "react";
import Styles from "./topBar.module.scss";
import { BiBell, BiRss } from "react-icons/bi";
import { Flex, Notification, ProfileDropdown } from "@components";
import { FaBars, FaChevronDown } from "react-icons/fa";
import CallNotifications from "components/CallNotification";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationCount } from "redux/slices/notification";
import { Drawer } from "@mui/material";
import { FiX } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Menus } from "@utils";

const TopBar = () => {
  const dispatch = useDispatch();
  const [sideNav, setSideNav] = useState(false);
  const [showNotificationDropDown, setShowNotificationDropDown] =
    useState(false);
  const queryClient = useQueryClient();
  const { user } = queryClient.getQueryData(["user"]) || {};

  const [_notifications, setNotifications] = useState(false);

  const { notificationCount } = useSelector((state) => state.notifications);
  const { token } = useSelector((state) => state.authentication);

  const openNotificationPanel = (e) => {
    e.preventDefault();
    setShowNotificationDropDown(!showNotificationDropDown);
    dispatch(
      setNotificationCount({ notificationType: "numberPoolJobs", count: 0 })
    );
  };

  return (
    <>
      <nav className={`${Styles.navbar} bg-blue px-2 sm:px-4 py-2.5`}>
        <div className="md:container flex flex-wrap justify-between items-center md:mx-auto">
          <NavLink to="/" className="flex items-center ">
            <span className="self-center text-white text-xl font-semibold whitespace-nowrap dark:text-white">
              Testing Scenario Management
            </span>
          </NavLink>
          <div className="md:block md:w-auto" id="navbar-default">
            <ul className="md:flex items-center md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 hidden">
              {token?.selected_partition && (
                <li className={Styles.menuItem}>
                  <a
                    className="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white"
                    onClick={() => {
                      dispatch(
                        setNotificationCount({
                          notificationType: "activeCalls",
                          count: 0,
                        })
                      );
                      setNotifications(true);
                    }}
                    aria-current="page"
                  >
                    <BiRss color="#fff" fontSize={"large"} />
                    {notificationCount?.activeCalls !== 0 && (
                      <div className={Styles.notificationCount}>new</div>
                    )}
                  </a>
                </li>
              )}

              {token?.selected_partition && (
                <li className={Styles.menuItem}>
                  <a
                    className="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white"
                    onClick={openNotificationPanel}
                    aria-current="page"
                  >
                    <BiBell color="#fff" fontSize={"large"} />
                    {notificationCount?.numberPoolJobs !== 0 && (
                      <div className={Styles.notificationCount}>
                        {notificationCount?.numberPoolJobs}
                      </div>
                    )}
                  </a>
                  <Notification
                    setShowNotificationDropDown={setShowNotificationDropDown}
                    showNotificationDropDown={showNotificationDropDown}
                  />
                </li>
              )}

              <li>
                <ProfileDropdown />
              </li>
            </ul>

            <div
              className="md:hidden items-center flex"
              onClick={() => {
                setSideNav(!sideNav);
              }}
            >
              <span className="self-center text-white text-xl font-semibold whitespace-nowrap dark:text-white">
                <FaBars />
              </span>
            </div>
          </div>
        </div>
      </nav>
      {/* <div className={`sideNaveBarWrapper ${sideNav ? 'active' : ''}`}>
                <OutsideClick onClick={() => { setSideNav(false) }}>
                    <div className={`sideNav ${sideNav ? 'active' : ''}`}>
                        <div className="md:hidden items-center flex" onClick={() => { setSideNav(!sideNav) }}>
                            <span className="self-center text-white text-xl font-semibold whitespace-nowrap dark:text-white">
                                <FaBars />
                            </span>
                        </div>
                    </div>
                </OutsideClick>
            </div> */}

      <Drawer
        anchor={"right"}
        open={sideNav}
        onClose={() => {
          setSideNav(false);
        }}
      >
        <div className={Styles.mobileSideBar}>
          <span
            className={Styles.closeIcon}
            onClick={() => {
              setSideNav(false);
            }}
          >
            <FiX />
          </span>
          <div className={Styles.profileContainer}>
            <Flex>
              <div
                className={`${Styles.avatar} flex justify-center items-center`}
              >
                {user?.first_name.substring(0, 2).toUpperCase()}
              </div>
              <div className="flex flex-col">
                <p>
                  {user?.first_name} {user?.last_name}
                </p>
                <p className="flex">
                  {token?.selected_partition?.company_name}
                </p>
              </div>
            </Flex>
          </div>

          <div className="flex flex-wrap justify-between items-center">
            <div className="w-full md:block md:w-auto" id="navbar-default">
              <ul className="flex flex-col p-1.5 mt-4 mx-4 md:flex-row md:space-x-4 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white ">
                {Menus.map(({ id, title, path, icon, children, type }) => (
                  <li key={id} className="relative">
                    {type === "link" && (
                      <NavLink
                        onClick={() => {
                          setSideNav(false);
                        }}
                        to={path}
                        className={({ isActive }) =>
                          `block py-2 pr-4  rounded  dark:text-white ${
                            Styles.menuItem
                          } ${isActive ? Styles.active : ""}`
                        }
                      >
                        <span>{title}</span>
                      </NavLink>
                    )}
                    {children && children.length > 0 && (
                      <ul className={Styles.subItem}>
                        {children.map(({ title, path }, index) => (
                          <li
                            key={index + 1}
                            onClick={() => {
                              setSideNav(false);
                            }}
                          >
                            <NavLink
                              to={path}
                              className={({ isActive }) =>
                                `block py-2 pr-4  rounded  dark:text-white ${
                                  Styles.menuItem
                                } ${isActive ? Styles.active : ""}`
                              }
                            >
                              <span>{title}</span>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Drawer>

      <CallNotifications
        notifications={_notifications}
        setNotifications={setNotifications}
      />
    </>
  );
};

export default TopBar;
