import { buildListParams } from "@utils";
import { api } from "core/api";

export const saveTestCase = async (payload) =>
    await api.post('trafficCycle?run_now=true', payload).then((response) => response.data)

export const updateTestCase = async (payload) =>
    await api.put(`trafficCycle/${payload.traffic_cycle_id}`, payload).then((response) => response.data)

export const getTestCases = async (filters) => {
    const params = buildListParams(filters);
    const response = await api.get('trafficCycle/executionPlan', { params });
    return response.data;
}
export const getTestCaseById = async (testCaseId) =>
    await api.get(`trafficCycle/${testCaseId}`).then((response) => { return response.data })

export const validateTestCase = async () =>
    await api.get('trafficCycle/rules/validate').then((response) => response.data)

export const publishExecutionPlan = async (executionPlanId) =>
    await api.post(`trafficCycle/executionPlan/${executionPlanId}/publish`, { run_now: true }).then((response) => response.data)

export const unPublishExecutionPlan = async (executionPlanId) =>
    await api.delete(`trafficCycle/executionPlan/${executionPlanId}/unPublish`).then((response) => response.data)

export const postTestCall = async (content) =>
    await api.post(`testing/calls/dial`, content).then((response) => response.data)

export const postTestSMS = async (content) =>
    await api.post(`testing/sms/send`, content).then((response) => response.data)


export const getTestCaseChartsData = async (params) => {
    const response = await api.get(`trafficCycle/templates/voice`, { params })
    return response.data;
}
export const getTestCaseChartsDataSMS = async (params) => {
    const response = await api.get(`trafficCycle/templates/sms`, { params })
    return response.data;
}
