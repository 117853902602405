import { api } from "core/api";
import { buildListParams } from "utils";

export const getTrunks = async (filters) => {
    let params = buildListParams(filters);
    const response = await api.get('trunks', { params });
    return response.data;
}

export const getTrunkById = async (trunkId) => {
    const response = await api.get(`trunks/${trunkId}`);
    return response.data;
}

export const postTrunk = async (requestObject) => {
    const response = await api.post('trunks', requestObject);
    return response.data;
}

export const updateTrunk = async (requestObject) => {
    const { trunk_id } = requestObject;
    const response = await api.put(`trunks/${trunk_id}`, requestObject);
    return response.data;
}

export const deleteTrunk = async (trunkId) => {
    const response = await api.delete(`trunks/${trunkId}`);
    return response.data;
}
