import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Styles from "./periodFilter.module.scss";

const PeriodFilter = ({ filter, onFilterChange }) => {
  const handleChange = (event, newAlignment) => {
    onFilterChange(newAlignment);
  };
  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={filter}
        className="gap-2"
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton value="daily" size="small" className={Styles.filter}>
          Daily
        </ToggleButton>
        <ToggleButton value="weekly" size="small" className={Styles.filter}>
          Weekly
        </ToggleButton>
        <ToggleButton value="monthly" size="small" className={Styles.filter}>
          Monthly
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

export default PeriodFilter;
