import React from "react";

const Content = ({ as = 'span', children, className, onClick, ...rest }) => {
    const Tag = `${as}`
    return (
        <Tag className={className} onClick={onClick} {...rest}>{children}</Tag>
    )
}

export default Content;
