import React from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import Styles from "./select.module.scss";

const Select = ({
  label,
  items = [],
  control,
  name,
  required = false,
  error,
  loading,
  bindLabel,
  bindValue,
  flex = "flex-col",
  customLabel = [],
  containerClassName,
  placeholder,
  isClearable = true,
  bottomElement,
  bottomElementStyle,
  ...props
}) => {
  return (
    <div
      className={`flex w-full ${Styles.selectContainer} ${containerClassName} ${flex}`}
    >
      {<label className={`form-control-label ${Styles.label}`}>{label}</label>}
      <Controller
        control={control}
        name={name}
        rules={{ required: required }}
        render={({ field }) => (
          <ReactSelect
            className={`${error && "validation-error"} ${Styles.select}`}
            isLoading={loading}
            placeholder={
              placeholder ? placeholder : `Select ${name.replace("_", " ")}`
            }
            isClearable={isClearable}
            // className={`${!!errors?.agency && 'error-validation'}`}
            options={items}
            getOptionValue={(option) => option[bindValue]}
            getOptionLabel={(option) =>
              customLabel.length > 0
                ? `${option[bindLabel]} (${option[customLabel[0]]}/${
                    option[customLabel[1]]
                  })`
                : `${option[bindLabel]}`
            }
            {...props}
            {...field}
          />
        )}
      />
      <div className={Styles.bottomElement} style={bottomElementStyle}>
        {bottomElement}
      </div>
    </div>
  );
};

export default Select;
