import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
    socket: null,
}

export const socketSlice = createSlice({
    name: 'activeCalls',
    initialState,
    reducers: {
        setSocketConnection: (state, action) => {
            const { payload } = action;
            state.socket = payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setSocketConnection } = socketSlice.actions

export default socketSlice.reducer