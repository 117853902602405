import { Box, Typography } from "@mui/material";
import React, { Component } from "react";
import Person from "@assets/img/gesture-person.svg";
import Blob from "@assets/img/blob-bottom.png";
import Shape from "@assets/img/shape.png";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // For debugging
    console.log("Error", { error, errorInfo });
    this.setState({ error, errorInfo });
  }
  render() {
    // Error path
    if (this.state.hasError) {
      return (
        <div
          className="bg-contain bg-no-repeat bg-bottom"
          style={{ backgroundImage: "url(" + Blob + ")" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <Typography variant="h3">
              <div>Oops!!! Something went wrong</div>
            </Typography>
            <div className="flex items-center" style={{ marginTop: 62 }}>
              <div
                className="rounded-md w-fit"
                style={{
                  padding: 18,
                  marginRight: 42,
                  backgroundColor: "#f1f1f1",
                  boxShadow: "3px 10px 19px #ddd",
                }}
              >
                <p
                  style={{
                    whiteSpace: "pre-wrap",
                    cursor: "pointer",
                    marginTop: 20,
                    width: 600,
                  }}
                >
                  <span className="font-bold text-red">
                    {this.state?.error && this.state?.error?.toString()}
                  </span>
                  <br />
                  <small>{this.state?.errorInfo?.componentStack}</small>
                </p>
              </div>

              <img src={Person} alt="Person" />
            </div>
          </Box>
          <img src={Shape} className="absolute bottom-0" alt="shape" />
        </div>
      );
    }

    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
